
import Vue from 'vue';
import { VueLoading } from 'vue-loading-template';
import Swal from 'sweetalert2';

import { familyModule } from '@/store/entityModules/family';
import { paymentModule } from '@/store/entityModules/payment';

import { PaymentMethodResponse } from '@API/src/component/home/payment/types';

interface DataType {
  childId: number;
  childName: string;
  fetchLoading: boolean;
  cardFetchLoading: boolean;
  putBtnLoading: boolean;
  deleteBtnLoading: boolean;
  selectedCategory: string | null;
  selectedCardSeq: string;
  paymentCategories: Array<{
    type: string;
    name: string;
  }>;
}

export default Vue.extend({
  name: 'Payment',

  components: {
    VueLoading,
  },

  data: (): DataType => ({
    childId: NaN,
    childName: '',
    fetchLoading: false,
    cardFetchLoading: false,
    putBtnLoading: false,
    deleteBtnLoading: false,
    selectedCategory: null,
    selectedCardSeq: '',
    paymentCategories: [
      {
        type: 'card',
        name: 'クレジットカード',
      },
      // {
      //   type: 'withdrawal',
      //   name: '口座引き落とし',
      // },
      // {
      //   type: 'invoice',
      //   name: '振り込み',
      // },
    ],
  }),

  computed: {
    family() {
      return familyModule.family;
    },
    payment() {
      if (this.childId && paymentModule.payments.length > 0) {
        const payment = paymentModule.payments.find((p) => {
          if (p.child.childId === this.childId) {
            return p;
          }
        });
        return payment ? payment : null;
      } else {
        return null;
      }
    },
    selectedCard() {
      if (this.childId && paymentModule.payments.length > 0) {
        const payment = paymentModule.payments.find((p) => {
          if (p.child.childId === this.childId) {
            return p;
          }
        });
        if (payment && payment.card) {
          return payment.card;
        }
        return null;
      } else {
        return null;
      }
    },
    cards() {
      return paymentModule.cards;
    },
  },

  watch: {
    payment: {
      handler(val: PaymentMethodResponse | null) {
        if (val !== null) {
          if (val.category === null) {
            this.selectedCategory = 'invoice';
          } else {
            this.selectedCategory = val.category;
          }
        }
      },
      deep: true,
    },
  },

  methods: {
    routerBack() {
      this.$router.replace('/payment/select', () => {});
    },
    toRegister() {
      const childId = String(this.childId);
      const childName = this.childName;
      this.$router.push({
        path: '/payment/register',
        name: 'PaymentRegister',
        query: {
          childId,
          childName,
        },
      });
    },
    isUsingCard(cardSeq: string) {
      return cardSeq === this.selectedCard?.cardSeq;
    },
    getExpireString(expire: string) {
      const str = expire.slice(0, 2) + '/' + expire.slice(2);
      return str;
    },
    selectCategory(category: string) {
      if (this.selectedCategory === 'card' && this.selectedCardSeq === '') {
        if (this.cards.length > 0) {
          this.selectedCardSeq = this.cards[0].cardSeq;
        }
      }
    },
    async fetchPaymentCardMethod() {
      this.cardFetchLoading = true;
      if (this.family) {
        const familyId = this.family.familyId;
        const response = await paymentModule.fetchPaymentCardMethod({
          familyId,
        });
      }
      this.cardFetchLoading = false;
    },
    async putPaymentMethod() {
      this.putBtnLoading = true;
      if (!this.selectedCategory) {
        Swal.fire({
          icon: 'warning',
          title: '支払方法を選択してください',
        });
      }
      if (this.selectedCategory === 'card' && !this.selectedCardSeq) {
        Swal.fire({
          icon: 'warning',
          title: 'クレジットカードが\n登録・選択\nされていません',
        });
        return;
      }
      if (this.payment && this.selectedCategory) {
        const response = await paymentModule.putPaymentMethod({
          childId: this.payment.child.childId,
          category: this.selectedCategory,
          cardSeq: this.selectedCardSeq ? this.selectedCardSeq : null,
        });
        if (response) {
          Swal.fire({
            icon: 'success',
            title: '保存が完了しました',
          });
        }
      }
      this.putBtnLoading = false;
    },
    async deletePaymentMethodCard(cardSeq: string) {
      this.deleteBtnLoading = true;
      if (this.family) {
        const familyId = this.family.familyId;
        const response = paymentModule.deletePaymentCardMethod({
          familyId,
          cardSeq,
        });
      }
      this.deleteBtnLoading = false;
    },
    async cardCheck () {
      if (!this.selectedCard) {
        Swal.fire({
            icon: 'warning',
            title: '決済カードの\n登録ができていません',
            text: 'カード登録を行い、情報をタップし「登録」ボタンを押してください。\nカード情報右上に「使用中」と表示されると登録完了です',
          });
      }
    }
  },

  async mounted() {
    if (typeof this.$route.query.childName === 'string') {
      this.childName = this.$route.query.childName;
    }
    if (typeof this.$route.query.childId === 'number') {
      this.childId = this.$route.query.childId;
    } else if (typeof this.$route.query.childId === 'string') {
      this.childId = Number(this.$route.query.childId);
    } else {
      this.routerBack();
    }

    if (!this.family) {
      this.routerBack();
    }

    if (this.payment) {
      this.selectedCategory = this.payment.category;
      if (this.payment.card) {
        this.selectedCardSeq = this.payment.card.cardSeq;
      }
    } else {
      this.routerBack();
    }

    this.fetchPaymentCardMethod();

    this.cardCheck ()
    
  },
});
