import { render, staticRenderFns } from "./Payment.vue?vue&type=template&id=2f6f8e1c&scoped=true"
import script from "./Payment.vue?vue&type=script&lang=ts"
export * from "./Payment.vue?vue&type=script&lang=ts"
import style0 from "./Payment.vue?vue&type=style&index=0&id=2f6f8e1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f6f8e1c",
  null
  
)

export default component.exports